import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { Tags } from '@/helpers/types';
import lambdaConstructor, {
  HttpMethod,
} from '@/utils/lambdaApi/lambdaConstructor';

const inputValues = [
  {
    name: 'venue.name',
    formLabel: 'Venue Name',
  },
  {
    name: 'venue.address.address1',
    formLabel: 'Street',
  },
  {
    name: 'venue.address.address2',
    formLabel: 'Suite',
  },
  {
    name: 'venue.address.city',
    formLabel: 'City',
  },
  {
    name: 'venue.address.state',
    formLabel: 'State',
  },
  {
    name: 'venue.address.postal',
    formLabel: 'Postal',
  },
  {
    name: 'venue.address.country',
    formLabel: 'Country',
  },
  {
    name: 'venue.venueSource',
    formLabel: 'Venue Source Type',
  },
  {
    name: 'venue.externalId',
    formLabel: 'Venue External Id',
  },
  {
    name: 'venue.externalGroupId',
    formLabel: 'Venue External Group Id',
  },
  {
    name: 'venue.locationType',
    formLabel: 'Location Type',
    formSubLabel: 'ONLINE or NEARBY',
  },
  {
    name: 'venue.enabled',
    formLabel: 'Enable or Disable Venue',
    formSubLabel: 'true or false',
  },
] as InputFieldConfigs[];

const submit = lambdaConstructor({
  serviceName: 'cn-venue-service',
  functionName: 'upsertONVenue',
  method: HttpMethod.POST,
});

export const upsertONVenue = {
  name: 'Upsert ONVenue',
  description: 'Upserts an ONVenue',
  inputValues,
  tags: [Tags.venues],
  submit,
};
