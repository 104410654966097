import { WorkflowStepTypes } from '@/components/Workflow';
import InputInjection from '@/utils/workflow/InputInjection';
import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { Tags } from '@/helpers/types';
import { redshiftEscape } from '@/utils/strings';

const inputValues = [
  {
    name: 'merchantName',
    formLabel: 'Merchant Name',
  },
] as InputFieldConfigs[];

const steps = [
  {
    name: 'Check if Merchant Already Exists',
    autorun: true,
    process: {
      type: WorkflowStepTypes.REDSHIFT_QUERY,
    },
    inputData: {
      query: new InputInjection(
        [0],
        ({
          merchantName,
        }: {
          merchantName: string;
        }) => `SELECT getuuid(m.merchant_id) as merchantId, m.name as merchantName
        FROM merchantdb.merchants m
        WHERE m.name ilike '%${redshiftEscape(merchantName)}%';`,
      ),
    },
  },
  {
    name: 'Check if Button Merchant',
    autorun: true,
    process: {
      type: WorkflowStepTypes.REDSHIFT_QUERY,
    },
    inputData: {
      query: new InputInjection(
        [0],
        ({
          merchantName,
        }: {
          merchantName: string;
        }) => `select og.brand_name, og.uuid as offerGroupUuid, og.offer_network_start_date, og.offer_network_end_date
        from affiliateoffersdb.offer_group og
        join affiliateoffersdb.offer o on og.id = o.offer_group_id
        join affiliateoffersdb.commission_split c on o.id = c.offer_id
        where 1 = 1
        and name ilike 'Button%${redshiftEscape(merchantName)}%'
        ;`,
      ),
    },
  },
];

export const merchantImplementationPreCheck = {
  steps,
  inputValues,
  name: 'Merchant Implementation PreCheck',
  description: 'Check new merchant to verify proper onboarding steps',
  tags: [Tags.newMerchantOnboarding],
};
