/**
 * The External Id here is referring to the unique identifier we can assign when adding a VMID/VSID to a merchant group
 * this identifier can then be used as reference to the collective group of VMIDs/VSIDs that have been marked with it
 *
 * Visa expects this value to be 25-characters with no special characters/spaces and in all caps
 *
 * for consistency and to match the model as we expect these activated cnids to belong to a certain merchant, we will use the merchant name
 * that has been converted to the expected format
 */

export const castStringToVisaExternalId = (name: string) => {
  return name
    .replace(/[^\w]/gi, '')
    .replace(/_/gi, '')
    .slice(0, 25)
    .toUpperCase();
};
