import { WorkflowStepTypes } from '@/components/Workflow';
import { UPSERT_BRAND_ASSET } from '@/services/graphql/upsertBrandAsset.gql';
import InputInjection from '@/utils/workflow/InputInjection';
import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { Tags } from '@/helpers/types';

const inputValues = [
  {
    name: 'brandId',
    formLabel: 'BrandId',
    col: 4,
  },
] as InputFieldConfigs[];

const steps = [
  {
    name: 'Upload new assets',
    autorun: false,
    process: {
      type: WorkflowStepTypes.UPLOAD_BUTTON_MERCHANT_ASSETS,
      submit: () => {},
    },
    inputData: {
      brandId: new InputInjection([0, 'brandId']),
    },
  },
  {
    name: 'Upsert Brand Assets',
    autorun: false,
    process: {
      type: WorkflowStepTypes.GRAPHQL_MUTATION,
      submit: UPSERT_BRAND_ASSET,
    },
    inputData: {
      brandId: new InputInjection([0, 'brandId']),
      bundle: 'DEFAULT_BRANDING',
      assets: [
        {
          path: new InputInjection([1, 'cardImage', 'path']),
          component: 'cardImage',
          __typename: 'BrandAssetCard',
          format: new InputInjection([1, 'cardImage', 'format']),
          width: new InputInjection([1, 'cardImage', 'width']),
          type: 'image',
          height: new InputInjection([1, 'cardImage', 'height']),
        },
        {
          path: new InputInjection([1, 'bannerImage', 'path']),
          component: 'banner',
          __typename: 'BrandAssetBanner',
          format: new InputInjection([1, 'bannerImage', 'format']),
          width: new InputInjection([1, 'bannerImage', 'width']),
          type: 'image',
          height: new InputInjection([1, 'bannerImage', 'height']),
        },
        {
          path: new InputInjection([1, 'logoImage', 'path']),
          component: 'icon',
          __typename: 'BrandAssetIcon',
          format: new InputInjection([1, 'logoImage', 'format']),
          width: new InputInjection([1, 'logoImage', 'width']),
          type: 'image',
          height: new InputInjection([1, 'logoImage', 'height']),
        },
      ],
    },
  },
  {
    name: 'Approve Assets',
    inputData: {
      link: new InputInjection(
        [0, 'brandId'],
        (input: any) =>
          `${window.location.origin}/workflow/approveAssets?brandId=${input}`,
      ),
      description:
        'External Process.  Follow the link to approve the brand assets',
    },
    process: {
      type: WorkflowStepTypes.EXTERNAL_STEP,
    },
  },
];

export const updateButtonMerchantAssets = {
  steps,
  inputValues,
  name: 'Update Button Merchant Assets',
  description: 'Update and approve assets for an existing Button merchant.',
  tags: [Tags.specialMerchants],
};
