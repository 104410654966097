import { WorkflowStepTypes } from '@/components/Workflow';
import InputInjection from '@/utils/workflow/InputInjection';
import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { Tags } from '@/helpers/types';
import { updateOfferGroupContent } from '../lambdaFunctions/updateOfferGroupContent';
import { redshiftEscape } from '@/utils/strings';
import { getArrayFromCheckboxSelections } from '@/helpers/getArrayFromCheckboxSelections';

const inputValues = [
  {
    name: 'merchantName',
    formLabel: 'Merchant Name',
  },
  {
    name: 'offerUrl',
    formLabel: 'Url',
  },
] as InputFieldConfigs[];

const steps = [
  {
    name: 'Get Offer Group Id',
    autorun: true,
    process: {
      type: WorkflowStepTypes.REDSHIFT_QUERY,
    },
    inputData: {
      query: new InputInjection(
        [0],
        ({
          merchantName,
        }: {
          merchantName: string;
        }) => `SELECT DISTINCT og.brand_name, og.uuid as offer_group_uuid
        FROM affiliateoffersdb.offer_group og
        JOIN affiliateoffersdb.offer o on og.id = o.offer_group_id
        JOIN affiliateoffersdb.commission_split c on o.id = c.offer_id
        WHERE 1 = 1
        AND name ilike 'Button%${redshiftEscape(merchantName)}%'
        ;`,
      ),
    },
  },
  {
    name: 'Select Offer',
    autorun: false,
    process: {
      type: WorkflowStepTypes.CHECKBOX_SELECTOR,
      submit: () => {},
    },
    inputData: {
      options: new InputInjection(
        [1],
        ({
          records,
        }: {
          records: { brand_name: string; offer_group_uuid: string }[];
        }) => {
          if (!records || !Array.isArray(records)) {
            return;
          }
          return records.map((record) => {
            return {
              id: `${record.offer_group_uuid}`,
              name: `Brand Name: ${record.brand_name}, Offer Group Uuid: ${record.offer_group_uuid}`,
            };
          });
        },
      ),
    },
  },
  {
    name: 'Update Offer Group Content',
    autorun: false,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: updateOfferGroupContent.submit,
    },
    inputData: {
      offerGroupUuid: new InputInjection([2], (result: Record<string, any>) => {
        if (!result || typeof result === 'string') {
          return;
        }
        return getArrayFromCheckboxSelections(result)[0];
      }),
      offerUrl: new InputInjection([0, 'offerUrl']),
    },
  },
];

export const updateButtonUrl = {
  steps,
  inputValues,
  name: 'Update Button Url',
  description: 'Replace current url for Button merchant with new one',
  tags: [Tags.specialMerchants],
};
