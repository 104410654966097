import gql from 'graphql-tag';

export const UPSERT_BRAND_ASSET = gql`
  mutation upsertBrandAsset(
    $brandId: ID!
    $bundle: BrandAssetBundleName!
    $assets: [JSON!]!
  ) {
    upsertBrandAsset(brandId: $brandId, bundle: $bundle, assets: $assets) {
      success
    }
  }
`;
