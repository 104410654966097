import { pathOr } from 'ramda';
import { WorkflowStepTypes } from '@/components/Workflow';
import InputInjection from '@/utils/workflow/InputInjection';
import {
  InputFieldConfigs,
  InputFieldTypes,
} from '@/components/WorkflowForm/WorkflowForm';
import { toggleCNTransactionsForCNId } from '../lambdaFunctions/toggleCNTransactionsForCNId';
import {
  CardNetwork,
  CardNetworkIdType,
  CardNetworkTransactionMode,
  Tags,
  VisaCommunityCode,
} from '@/helpers/types';
import { visaMerchantGroups } from '@/helpers/commonInputSelectOptions';
import { getTransactionFlowSourcesForCNId } from '../lambdaFunctions/getTransactionFlowSourcesForCNId';
import { getArrayFromCheckboxSelections } from '@/helpers/getArrayFromCheckboxSelections';

export const isProdEnv = pathOr('prod', ['NODE_ENV'], config) === 'prod';
const inputValues = [
  {
    name: 'merchantName',
    formLabel: 'Merchant Name',
    formSubLabel: 'existing active merchant name',
  },
  {
    name: 'newVisaMerchantGroup',
    formLabel: 'New Visa Merchant Group',
    options: visaMerchantGroups,
    type: InputFieldTypes.SELECT,
  },
] as InputFieldConfigs[];

const steps = [
  {
    name: 'Get Active VMIDs',
    autorun: true,
    process: {
      type: WorkflowStepTypes.REDSHIFT_QUERY,
    },
    inputData: {
      query: new InputInjection(
        [0],
        ({ merchantName }: { merchantName: string }) => `with onvenue_total as (
          SELECT COUNT(DISTINCT onv.id) as onvenue_count, m.merchant_id
          FROM cnvenuedb.venues onv
          JOIN merchantdb.source_locations sl on onv.uuid = sl.source_location_id
          JOIN merchantdb.locations l on sl.location_id = l.location_id
          JOIN merchantdb.merchants m on l.merchant_id = m.merchant_id
          WHERE 1=1
          AND m.name ilike '%${merchantName}%'
          AND onv.venue_source_id = 1
          GROUP BY 2
      ), total_cnvenues_per_vmid as(
          SELECT COUNT(DISTINCT cnv.id) as cnvenues_per_vmid_count, cnis.external_id as vmid, ot.onvenue_count as onvenue_count
          FROM onvenue_total ot
          JOIN merchantdb.merchants m on ot.merchant_id = m.merchant_id
          JOIN merchantdb.locations l on m.merchant_id = l.merchant_id
          JOIN merchantdb.source_locations sl on l.location_id = sl.location_id
          JOIN cnvenuedb.venues onv on sl.source_location_id = onv.uuid
          JOIN cnvenuedb.offernetwork_to_cardnetwork_venue_mappings otcvm on otcvm.on_venue_id = onv.id AND onv.venue_source_id = 1
          JOIN cnvenuedb.venues cnv on cnv.id = otcvm.cn_venue_id AND cnv.venue_source_id = 4 AND cnv.enabled = 1
          JOIN cnvenuedb.venue_to_card_network_id vtcni on vtcni.venue_id = cnv.id
          JOIN cnvenuedb.card_network_ids cnis on cnis.id = vtcni.card_network_id AND cnis.id_type = 'VMID'
          JOIN cnvenuedb.names n2 on cnv.name_id = n2.id
          WHERE 1=1
          GROUP BY 2, 3
      ) SELECT DISTINCT (CAST(cnvenues_per_vmid_count as float)/onvenue_count)*100 as percent_of_mapped_vmids, tcpv.vmid, vtfsvc.merchant_group
      FROM total_cnvenues_per_vmid tcpv
      LEFT JOIN cnmgmtdb.view_transaction_flow_source_visa_current vtfsvc on vtfsvc.card_network_external_id = tcpv.vmid AND vtfsvc.card_network_external_id_type = 'VMID'
      WHERE vtfsvc.state = 'ACTIVE' AND percent_of_mapped_vmids >= 1
      ORDER BY percent_of_mapped_vmids DESC;`,
      ),
      retries: 10,
    },
  },
  {
    name: 'Select VMID',
    autorun: false,
    process: {
      type: WorkflowStepTypes.CHECKBOX_SELECTOR,
      submit: () => {},
    },
    inputData: {
      metaData: {
        autoSkip: false,
      },
      options: new InputInjection(
        [1],
        ({
          records,
        }: {
          records: {
            percent_of_mapped_vmids: string;
            vmid: string;
            merchant_group: string;
          }[];
        }) => {
          if (!records || !Array.isArray(records)) {
            return;
          }
          return records.map((record) => {
            return {
              id: `${record.vmid}:::${record.merchant_group}`,
              name: `Percent of Locations with VMID: ${record.percent_of_mapped_vmids}; VMID: ${record.vmid}`,
            };
          });
        },
      ),
    },
  },
  {
    name: 'Enable VMID in new Merchant Group',
    autorun: false,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: toggleCNTransactionsForCNId.submit,
    },
    inputData: {
      metaData: {
        autoSkip: false,
      },
      mode: CardNetworkTransactionMode.ACTIVATE,
      cardNetwork: CardNetwork.VISA,
      cardNetworkExternalIdType: CardNetworkIdType.VMID,
      cardNetworkExternalId: new InputInjection(
        [2],
        (result: Record<string, any>) => {
          if (!result || typeof result === 'string') {
            return;
          }
          const selections = getArrayFromCheckboxSelections(result);
          return selections[0].split(':::')[0];
        },
      ),
      merchantGroup: new InputInjection([0, 'newVisaMerchantGroup']),
      communityCode: isProdEnv
        ? VisaCommunityCode.DOSHCL
        : VisaCommunityCode.DOSHCTECL,
    },
  },
  {
    name: 'Deactivate VMID',
    autorun: false,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: toggleCNTransactionsForCNId.submit,
    },
    inputData: {
      metaData: {
        autoSkip: false,
      },
      mode: CardNetworkTransactionMode.DEACTIVATE,
      cardNetwork: CardNetwork.VISA,
      cardNetworkExternalIdType: CardNetworkIdType.VMID,
      cardNetworkExternalId: new InputInjection(
        [2],
        (result: Record<string, any>) => {
          if (!result || typeof result === 'string') {
            return;
          }
          const selections = getArrayFromCheckboxSelections(result);
          return selections[0].split(':::')[0];
        },
      ),
      merchantGroup: new InputInjection([2], (result: Record<string, any>) => {
        if (!result) {
          return;
        }
        const selections = getArrayFromCheckboxSelections(result);
        return selections[0].split(':::')[1];
      }),
      communityCode: isProdEnv
        ? VisaCommunityCode.DOSHCL
        : VisaCommunityCode.DOSHCTECL,
    },
  },
  {
    name: 'Get VMID State',
    autorun: true,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: getTransactionFlowSourcesForCNId.submit,
    },
    inputData: {
      cardNetwork: CardNetwork.VISA,
      cardNetworkExternalIdType: CardNetworkIdType.VMID,
      cardNetworkExternalId: new InputInjection(
        [2],
        (result: Record<string, any>) => {
          if (!result || typeof result === 'string') {
            return;
          }
          const selections = getArrayFromCheckboxSelections(result);
          return selections[0].split(':::')[0];
        },
      ),
    },
  },
  {
    name: 'Result',
    autorun: true,
    process: {
      type: WorkflowStepTypes.DISPLAY_RESULT,
    },
    inputData: {
      merchantName: new InputInjection([0, 'merchantName']),
      oldMerchantGroup: new InputInjection(
        [2],
        (result: Record<string, any>) => {
          if (!result || typeof result === 'string') {
            return;
          }
          const selections = getArrayFromCheckboxSelections(result);
          return selections[0].split(':::')[1];
        },
      ),
      newMerchantGroup: new InputInjection([0, 'newVisaMerchantGroup']),
      newVmidState: new InputInjection([5], (result: any) => {
        if (!result) {
          return;
        }
        return result;
      }),
    },
  },
];

export const updateMerchantGroupForActiveVmid = {
  steps,
  inputValues,
  name: 'Update Merchant Group for Active VMID',
  description: 'Update Merchant Group for Active VMID',
  tags: [Tags.merchantGroups, Tags.cnids, Tags.transactions],
};
