import { WorkflowStepTypes } from '@/components/Workflow';
import InputInjection from '@/utils/workflow/InputInjection';
import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { Tags } from '@/helpers/types';
import { redshiftEscape } from '@/utils/strings';

const inputValues = [
  {
    name: 'merchantName',
    formLabel: 'Merchant Name',
  },
  {
    name: 'activationDate',
    formLabel: 'Date of Launch',
    formSubLabel: 'YYYY-MM-DD',
  },
  {
    name: 'offerUuid',
    formLabel: 'Offer Uuid',
  },
] as InputFieldConfigs[];

const steps = [
  {
    name: 'Get Dosh Transactions for Merchant',
    autorun: true,
    process: {
      type: WorkflowStepTypes.REDSHIFT_QUERY,
    },
    inputData: {
      query: new InputInjection(
        [0],
        ({
          merchantName,
          activationDate,
        }: {
          merchantName: string;
          activationDate: string;
        }) =>
          `
        SELECT
            cted.venue_name,
            cte.card_network,
            cte.marketplace,
            l.type,
            COUNT(DISTINCT cte.transaction_id)
        FROM empyrdb.card_transaction_events cte
        JOIN empyrdb.card_transaction_events_debug cted ON cte.id = cted.card_transaction_event_id
        JOIN empyrdb.card_transaction_events_card_network_ids ctecni ON cte.id = ctecni.card_transaction_event_id
        JOIN cnvenuedb.card_network_ids cnis ON ctecni.card_network_external_id = cnis.external_id
        JOIN cnvenuedb.venue_to_card_network_id vtcni ON vtcni.card_network_id = cnis.id
        JOIN cnvenuedb.venues cnv ON vtcni.venue_id = cnv.id
        JOIN cnvenuedb.offernetwork_to_cardnetwork_venue_mappings otcvm ON otcvm.cn_venue_id = cnv.id
        JOIN cnvenuedb.venues onv ON onv.id = otcvm.on_venue_id
        JOIN merchantdb.source_locations sl ON sl.source_location_id = onv.uuid
        JOIN merchantdb.locations l ON l.location_id = sl.location_id
        WHERE 1=1
            AND cte.transaction_timestamp >=  '${activationDate}'
            AND cted.venue_name ilike '%${redshiftEscape(merchantName)}%'   
            AND cnis.card_network IN ('VISA','MASTERCARD','AMEX')
            AND cte.marketplace = 'DOSH'
            AND onv.enabled = 1
        GROUP BY 4,3,2,1
        ORDER BY 4,3,2
        ;`,
      ),
      retries: 10,
    },
  },
  {
    name: 'Get CLOFs',
    autorun: true,
    process: {
      type: WorkflowStepTypes.REDSHIFT_QUERY,
    },
    inputData: {
      query: new InputInjection(
        [0],
        ({
          offerUuid,
          activationDate,
        }: {
          offerUuid: string;
          activationDate: string;
        }) =>
          `
            SELECT SUM(clof.reward_amount), clof.marketplace, clof.offer_segment_id
            FROM cardlinkedoffersdb.card_linked_offer_fulfillments clof
            WHERE clof.offer_id = '${offerUuid}'
                AND clof.created_at >= '${activationDate}'
            GROUP BY 2,3
            ORDER BY 2,3
            ;`,
      ),
      retries: 20,
    },
  },
];

export const postLaunchQA = {
  steps,
  inputValues,
  name: 'Post Launch QA',
  description: 'Verify transactions and CLOFs post launch',
  tags: [Tags.newMerchantOnboarding, Tags.qa],
};
