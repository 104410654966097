import { WorkflowStepTypes } from '@/components/Workflow';
import InputInjection from '@/utils/workflow/InputInjection';
import {
  InputFieldConfigs,
  InputFieldTypes,
} from '@/components/WorkflowForm/WorkflowForm';
import { toggleCNTransactionsForCNId } from '../lambdaFunctions/toggleCNTransactionsForCNId';
import { toggleVenueTransactionFlowByOfferUuid } from '../lambdaFunctions/toggleVenueTransactionFlowByOfferUuid';
import { markCNTransactionsForCNId } from '../lambdaFunctions/markCNTransactionsForCNId';
import {
  CardNetwork,
  CardNetworkIdType,
  CardNetworkTransactionMode,
  LocationStatusToggleMode,
  Tags,
  VisaActivationType,
  VisaCommunityCode,
  VisaMerchantGroup,
  YesOrNo,
} from '@/helpers/types';
import {
  offerLocationTypeOptions,
  yesOrNoOptions,
  visaActivationTypeOptions,
  visaMerchantGroupOptions,
} from '@/helpers/commonInputSelectOptions';
import { redshiftEscape } from '@/utils/strings';
import { getArrayFromCheckboxSelections } from '@/helpers/getArrayFromCheckboxSelections';

const inputValues = [
  {
    name: 'merchantName',
    formLabel: 'Name of Merchant',
  },
  {
    name: 'locationType',
    formLabel: 'Location Type',
    type: InputFieldTypes.SELECT,
    options: offerLocationTypeOptions,
  },
  {
    name: 'activateTransactions',
    formLabel: 'Activate Transactions?',
    type: InputFieldTypes.SELECT,
    options: yesOrNoOptions,
  },
  {
    name: 'visaMerchantGroup',
    formLabel: 'Visa Merchant Group',
    type: InputFieldTypes.SELECT,
    options: visaMerchantGroupOptions,
  },
  {
    name: 'activationType',
    formLabel: 'Select Activation Type',
    type: InputFieldTypes.SELECT,
    options: visaActivationTypeOptions,
  },
] as InputFieldConfigs[];

const steps = [
  {
    name: 'Process VMID Activation Selections',
    autorun: true,
    process: {
      type: WorkflowStepTypes.PREPROCESS_DATA,
    },
    inputData: {
      skipVMIDActivation: new InputInjection(
        [0],
        ({
          merchantName,
          activateTransactions,
          activationType,
        }: {
          merchantName: string;
          activateTransactions: string;
          activationType: VisaActivationType;
        }) => {
          if (!merchantName) {
            return;
          }
          return (
            activateTransactions === YesOrNo.no ||
            activationType === VisaActivationType.VSID
          );
        },
      ),
      visaOfferActivationType: new InputInjection(
        [0],
        ({ activationType }: { activationType: VisaActivationType }) => {
          if (!activationType) {
            return;
          }
          return activationType === VisaActivationType.VISA_OFFER;
        },
      ),
    },
  },
  {
    name: 'Get Potential VMIDs to Activate',
    autorun: true,
    process: {
      type: WorkflowStepTypes.REDSHIFT_QUERY,
    },
    inputData: {
      metaData: {
        autoSkip: new InputInjection(
          [0],
          ({ activationType }: { activationType: VisaActivationType }) => {
            if (!activationType) {
              return;
            }
            return activationType === VisaActivationType.VSID;
          },
        ),
      },
      query: new InputInjection(
        [0],
        ({ merchantName }: { merchantName: string }) =>
          `with onvenue_total as (
            SELECT COUNT(DISTINCT onv.id) as onvenue_count, m.merchant_id
            FROM cnvenuedb.venues onv
            JOIN merchantdb.source_locations sl on onv.uuid = sl.source_location_id
            JOIN merchantdb.locations l on sl.location_id = l.location_id
            JOIN merchantdb.merchants m on l.merchant_id = m.merchant_id
            WHERE 1=1
            AND m.name ilike '%${redshiftEscape(merchantName)}%'
            AND onv.venue_source_id = 1
            GROUP BY 2
        ), total_cnvenues_per_vmid as(
            SELECT COUNT(DISTINCT cnv.id) as cnvenues_per_vmid_count, cnis.external_id as vmid, ot.onvenue_count as onvenue_count
            FROM onvenue_total ot
            JOIN merchantdb.merchants m on ot.merchant_id = m.merchant_id
            JOIN merchantdb.locations l on m.merchant_id = l.merchant_id
            JOIN merchantdb.source_locations sl on l.location_id = sl.location_id
            JOIN cnvenuedb.venues onv on sl.source_location_id = onv.uuid
            JOIN cnvenuedb.offernetwork_to_cardnetwork_venue_mappings otcvm on otcvm.on_venue_id = onv.id AND onv.venue_source_id = 1
            JOIN cnvenuedb.venues cnv on cnv.id = otcvm.cn_venue_id AND cnv.venue_source_id = 4 AND cnv.enabled = 1
            JOIN cnvenuedb.venue_to_card_network_id vtcni on vtcni.venue_id = cnv.id
            JOIN cnvenuedb.card_network_ids cnis on cnis.id = vtcni.card_network_id AND cnis.id_type = 'VMID'
            JOIN cnvenuedb.names n2 on cnv.name_id = n2.id
            WHERE 1=1
            GROUP BY 2, 3
        ) SELECT DISTINCT (CAST(cnvenues_per_vmid_count as float)/onvenue_count)*100 as percent_of_mapped_vmids, tcpv.vmid
        FROM total_cnvenues_per_vmid tcpv
        LEFT JOIN cnmgmtdb.view_transaction_flow_source_visa_current vtfsvc on vtfsvc.card_network_external_id = tcpv.vmid AND vtfsvc.card_network_external_id_type = 'VMID'
        WHERE (vtfsvc.state != 'ACTIVE' OR vtfsvc.state IS NULL)
        AND percent_of_mapped_vmids >= 1
        ORDER BY percent_of_mapped_vmids DESC;`,
      ),
      retries: 20,
    },
  },
  {
    name: 'Select VMIDs to Activate (Verify in Visa Portal)',
    autorun: false,
    process: {
      type: WorkflowStepTypes.CHECKBOX_SELECTOR,
      submit: () => {},
    },
    inputData: {
      metaData: {
        autoSkip: new InputInjection(
          [0],
          ({ activationType }: { activationType: VisaActivationType }) => {
            if (!activationType) {
              return;
            }
            return activationType === VisaActivationType.VSID;
          },
        ),
      },
      options: new InputInjection(
        [2],
        ({
          records,
        }: {
          records: { percent_of_mapped_vmids: string; vmid: string }[];
        }) => {
          if (!records || !Array.isArray(records)) {
            return;
          }
          return records.map((record) => {
            return {
              id: `${record.vmid}`,
              name: `Percent of Locations with VMID: ${record.percent_of_mapped_vmids}; VMID: ${record.vmid}`,
            };
          });
        },
      ),
    },
  },
  {
    name: 'Process Selections',
    autorun: true,
    process: {
      type: WorkflowStepTypes.PREPROCESS_DATA,
    },
    inputData: {
      selectedVMIDs: new InputInjection([3], (result: Record<string, any>) => {
        if (!result || typeof result === 'string') {
          return;
        }
        return getArrayFromCheckboxSelections(result);
      }),
      merchantGroup: new InputInjection([0, 'visaMerchantGroup']),
    },
  },
  {
    name: 'Create VMID Activation Array',
    autorun: true,
    process: {
      type: WorkflowStepTypes.PREPROCESS_DATA,
    },
    inputData: {
      metaData: {
        autoSkip: new InputInjection(
          [0],
          ({ activationType }: { activationType: VisaActivationType }) => {
            if (!activationType) {
              return;
            }
            return activationType !== VisaActivationType.VMID;
          },
        ),
      },
      activationArray: new InputInjection(
        [4],
        ({
          selectedVMIDs,
          merchantGroup,
        }: {
          selectedVMIDs: unknown[];
          merchantGroup: string;
        }) => {
          if (!selectedVMIDs) {
            return;
          }
          return !Array.isArray(selectedVMIDs)
            ? []
            : selectedVMIDs.map((selection) => {
                return {
                  mode: CardNetworkTransactionMode.ACTIVATE,
                  cardNetwork: CardNetwork.VISA,
                  cardNetworkExternalIdType: CardNetworkIdType.VMID,
                  cardNetworkExternalId: selection,
                  merchantGroup,
                  communityCode: VisaCommunityCode.DOSHCL,
                };
              });
        },
      ),
    },
  },
  {
    name: 'Activate VMIDs',
    autorun: false,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: toggleCNTransactionsForCNId.submit,
    },
    inputData: {
      metaData: {
        autoSkip: new InputInjection(
          [1],
          ({
            skipVMIDActivation,
            visaOfferActivationType,
            status,
          }: {
            skipVMIDActivation: boolean;
            visaOfferActivationType: boolean;
            status: string;
          }) => {
            if (!status || status !== 'success') {
              return;
            }
            return skipVMIDActivation || visaOfferActivationType;
          },
        ),
      },
      multipleInvocations: true,
      invocationArray: new InputInjection([5, 'activationArray']),
    },
  },
  {
    name: 'Preprocess CNID array for next step',
    autorun: true,
    process: {
      type: WorkflowStepTypes.PREPROCESS_DATA,
    },
    inputData: {
      metaData: {
        autoSkip: new InputInjection(
          [0],
          ({ activationType }: { activationType: VisaActivationType }) => {
            if (!activationType) {
              return;
            }
            return activationType !== VisaActivationType.VISA_OFFER;
          },
        ),
      },
      markCNArray: new InputInjection(
        [4],
        ({ selectedVMIDs }: { selectedVMIDs: any[] }) => {
          if (!selectedVMIDs) {
            return;
          }
          return !Array.isArray(selectedVMIDs)
            ? []
            : selectedVMIDs.map((selection) => {
                return {
                  mode: CardNetworkTransactionMode.ACTIVATE,
                  cardNetwork: CardNetwork.VISA,
                  cardNetworkExternalIdType: CardNetworkIdType.VMID,
                  cardNetworkExternalId: selection,
                  merchantGroup:
                    VisaMerchantGroup.PLACEHOLDER_FOR_VISA_OFFER_ACTIVATIONS,
                  communityCode: VisaCommunityCode.DOSHCL,
                };
              });
        },
      ),
    },
  },
  {
    name: 'Mark CN transactions for CNID for VISA Offers',
    autorun: false,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: markCNTransactionsForCNId.submit,
    },
    inputData: {
      metaData: {
        autoSkip: new InputInjection(
          [0],
          ({ activationType }: { activationType: VisaActivationType }) => {
            if (!activationType) {
              return;
            }
            return activationType !== VisaActivationType.VISA_OFFER;
          },
        ),
      },
      multipleInvocations: true,
      invocationArray: new InputInjection([7, 'markCNArray']),
    },
  },
  {
    name: 'Get Offer Uuid and Brand CNIds',
    autorun: true,
    process: {
      type: WorkflowStepTypes.REDSHIFT_QUERY,
    },
    inputData: {
      query: new InputInjection(
        [0],
        ({
          merchantName,
          locationType,
        }: {
          merchantName: string;
          locationType: string;
        }) =>
          `SELECT DISTINCT getuuid(o.offer_id) as offer_uuid, cnis.card_network as card_network, cnis.external_id as brand_id
          FROM merchantdb.offers o
          JOIN merchantdb.merchants m on o.merchant_id = m.merchant_id
          JOIN merchantdb.coupons c2 on o.coupon_id = c2.coupon_id
          JOIN merchantdb.campaigns c on c.offer_id = o.offer_id
          JOIN merchantdb.coupons_locations cl on c2.coupon_id = cl.coupon_id
          JOIN merchantdb.locations l on cl.location_id = l.location_id
          JOIN merchantdb.source_locations sl on sl.location_id = l.location_id
          JOIN cnvenuedb.venues onv on onv.uuid = sl.source_location_id
          LEFT JOIN cnvenuedb.offernetwork_to_cardnetwork_venue_mappings otcvm on otcvm.on_venue_id = onv.id
          LEFT JOIN cnvenuedb.venues cnv on otcvm.cn_venue_id = cnv.id
          LEFT JOIN cnvenuedb.venue_to_card_network_id vtcni on vtcni.venue_id = cnv.id
          LEFT JOIN cnvenuedb.card_network_ids cnis on vtcni.card_network_id = cnis.id
          WHERE 1=1
          AND m.name ilike '%${redshiftEscape(merchantName)}%'
          AND l.type = '${locationType}'
          AND c.end_date > current_date
          AND c.start_date >= current_date
          AND o.deleted = 0
          AND (cnis.id_type = 'BRAND' OR cnis.id_type is null)
          ;`,
      ),
      retries: 20,
    },
  },
  {
    name: 'Toggle By Offer Uuid',
    autorun: true,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: toggleVenueTransactionFlowByOfferUuid.submit,
    },
    inputData: {
      mode: LocationStatusToggleMode.TURN_ON,
      skipCNIdActivation: new InputInjection(
        [0],
        ({ activateTransactions }: { activateTransactions: string }) => {
          return activateTransactions === YesOrNo.no;
        },
      ),
      offerUuid: new InputInjection(
        [9],
        ({
          records,
        }: {
          records: {
            offer_uuid: string;
            card_network: string;
            brand_id: string;
          }[];
        }) => {
          if (!records || !records.length) {
            return;
          }
          return records[0].offer_uuid;
        },
      ),
    },
  },
  {
    name: 'Mark Visa Brand CNId',
    autorun: true,
    inputData: {
      metaData: {
        autoSkip: new InputInjection(
          [9],
          ({
            records,
            status,
          }: {
            records: {
              offer_uuid: string;
              card_network: string;
              brand_id: string;
            }[];
            status: string;
          }) => {
            return (
              status === 'success' &&
              (!records ||
                !records.length ||
                !records.some(
                  (record) => record.card_network === CardNetwork.VISA,
                ))
            );
          },
        ),
      },
      mode: CardNetworkTransactionMode.ACTIVATE,
      cardNetwork: CardNetwork.VISA,
      cardNetworkExternalIdType: CardNetworkIdType.BRAND,
      cardNetworkExternalId: new InputInjection(
        [9],
        ({
          records,
        }: {
          records: {
            offer_uuid: string;
            card_network: string;
            brand_id: string;
          }[];
        }) => {
          if (!records || !records.length) {
            return;
          }
          const visaRecord = records.find(
            (record) => record.card_network === CardNetwork.VISA,
          );
          return visaRecord ? visaRecord.brand_id : undefined;
        },
      ),
      merchantGroup: new InputInjection(
        [0],
        ({
          merchantName,
          visaMerchantGroup,
        }: {
          merchantName: string;
          visaMerchantGroup: string;
        }) => {
          if (!merchantName) {
            return;
          }
          return visaMerchantGroup || 'Dosh Master MG';
        },
      ),
      communityCode: VisaCommunityCode.DOSHCL,
    },
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: markCNTransactionsForCNId.submit,
    },
  },
  {
    name: 'Mark Mastercard Brand CNId',
    autorun: true,
    inputData: {
      metaData: {
        autoSkip: new InputInjection(
          [9],
          ({
            records,
            status,
          }: {
            records: {
              offer_uuid: string;
              card_network: string;
              brand_id: string;
            }[];
            status: string;
          }) => {
            return (
              status === 'success' &&
              (!records ||
                !records.length ||
                !records.some(
                  (record) => record.card_network === CardNetwork.MASTERCARD,
                ))
            );
          },
        ),
      },
      mode: CardNetworkTransactionMode.ACTIVATE,
      cardNetwork: CardNetwork.MASTERCARD,
      cardNetworkExternalIdType: CardNetworkIdType.BRAND,
      cardNetworkExternalId: new InputInjection(
        [9],
        ({
          records,
        }: {
          records: {
            offer_uuid: string;
            card_network: string;
            brand_id: string;
          }[];
        }) => {
          if (!records || !records.length) {
            return;
          }
          const mastercardRecord = records.find(
            (record) => record.card_network === CardNetwork.MASTERCARD,
          );
          return mastercardRecord ? mastercardRecord.brand_id : undefined;
        },
      ),
    },
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: markCNTransactionsForCNId.submit,
    },
  },
];

export const activateLocations = {
  steps,
  inputValues,
  name: 'Activate Locations and Card Networks',
  description: 'Activate locations and (optionally) card networks for an offer',
  tags: [Tags.newMerchantOnboarding, Tags.transactions],
};
