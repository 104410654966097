import { WorkflowStepTypes } from '@/components/Workflow';
import { ADD_BRAND } from '@/services/graphql/addBrand.gql';
import { UPSERT_BRAND_ASSET } from '@/services/graphql/upsertBrandAsset.gql';
import { SET_BRAND_INFO } from '@/services/graphql/setBrandInfo.gql';
import { saveAffiliateOffer } from '@/services/lambdaFunctions/saveAffiliateOffer';
import InputInjection from '@/utils/workflow/InputInjection';
import {
  InputFieldConfigs,
  InputFieldTypes,
} from '@/components/WorkflowForm/WorkflowForm';
import { Tags } from '@/helpers/types';

const inputValues = [
  {
    name: 'brandName',
    formLabel: 'Brand Name',
    col: 4,
  },
  {
    name: 'brandId',
    formLabel: 'BrandId',
    col: 4,
  },
  {
    name: 'description',
    formLabel: 'Description',
    col: 4,
  },
  {
    name: 'startDate',
    formLabel: 'Start Date',
    formSubLabel: 'format as `2020-10-22T16:25:00.000Z`',
    col: 4,
  },
  {
    name: 'endDate',
    formLabel: 'End Date',
    formSubLabel: 'format as `2020-10-22T16:25:00.000Z`',
    col: 4,
  },
  {
    name: 'reportingWindowDays',
    formLabel: 'Reporting Window Days',
    col: 4,
  },
  {
    name: 'cashbackRestrictions',
    formLabel: 'Cashback Restrictions',
    col: 12,
    type: InputFieldTypes.TEXT_ARRAY,
  },
] as InputFieldConfigs[];

const steps = [
  {
    name: 'Get button merchant data',
    autorun: false,
    process: {
      type: WorkflowStepTypes.BUTTON_MERCHANT_DATA,
      submit: () => {},
    },
    inputData: {},
  },
  {
    name: 'Upload new assets',
    autorun: false,
    process: {
      type: WorkflowStepTypes.UPLOAD_BUTTON_MERCHANT_ASSETS,
      submit: () => {},
    },
    inputData: {
      brandId: new InputInjection([0, 'brandId']),
    },
  },
  {
    name: 'Add Brand',
    autorun: false,
    process: {
      type: WorkflowStepTypes.GRAPHQL_MUTATION,
      submit: ADD_BRAND,
    },
    inputData: {
      brandId: new InputInjection([0, 'brandId']),
    },
  },
  {
    name: 'Upsert Brand Assets',
    autorun: false,
    process: {
      type: WorkflowStepTypes.GRAPHQL_MUTATION,
      submit: UPSERT_BRAND_ASSET,
    },
    inputData: {
      brandId: new InputInjection([0, 'brandId']),
      bundle: 'DEFAULT_BRANDING',
      assets: [
        {
          path: new InputInjection([2, 'cardImage', 'path']),
          component: 'cardImage',
          __typename: 'BrandAssetCard',
          format: new InputInjection([2, 'cardImage', 'format']),
          width: new InputInjection([2, 'cardImage', 'width']),
          type: 'image',
          height: new InputInjection([2, 'cardImage', 'height']),
        },
        {
          path: new InputInjection([2, 'bannerImage', 'path']),
          component: 'banner',
          __typename: 'BrandAssetBanner',
          format: new InputInjection([2, 'bannerImage', 'format']),
          width: new InputInjection([2, 'bannerImage', 'width']),
          type: 'image',
          height: new InputInjection([2, 'bannerImage', 'height']),
        },
        {
          path: new InputInjection([2, 'logoImage', 'path']),
          component: 'icon',
          __typename: 'BrandAssetIcon',
          format: new InputInjection([2, 'logoImage', 'format']),
          width: new InputInjection([2, 'logoImage', 'width']),
          type: 'image',
          height: new InputInjection([2, 'logoImage', 'height']),
        },
      ],
    },
  },
  {
    name: 'Set Brand Info',
    autorun: false,
    process: {
      type: WorkflowStepTypes.GRAPHQL_MUTATION,
      submit: SET_BRAND_INFO,
    },
    inputData: {
      brandId: new InputInjection([0, 'brandId']),
      info: {
        name: new InputInjection([0, 'brandName']),
        description: new InputInjection([0, 'description']),
      },
    },
  },
  {
    name: 'Approve Assets',
    inputData: {
      link: new InputInjection(
        [0, 'brandId'],
        (input: any) =>
          `${window.location.origin}/workflow/approveAssets?brandId=${input}`,
      ),
      description:
        'External Process.  Follow the link to approve the brand assets',
    },
    process: {
      type: WorkflowStepTypes.EXTERNAL_STEP,
    },
  },
  {
    name: 'Save Affiliate Offer',
    autorun: false,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: saveAffiliateOffer.submit,
    },
    inputData: {
      brand: new InputInjection([0, 'brandId']),
      brandName: new InputInjection([0, 'brandName']),
      cashbackRestrictions: new InputInjection(
        [0, 'cashbackRestrictions'],
        (input: any) => {
          if (Array.isArray(input)) {
            const filtered = input.filter(Boolean);
            if (filtered.length === 0) {
              return null;
            }
            return filtered;
          }
          return input;
        },
      ),
      offerNetwork: 'BUTTON',
      offerNetworkMerchantId: new InputInjection([1, 'id']),
      offerNetworkOfferGroupId: new InputInjection(
        [0, 'brandId'],
        (input: any) => `button#offerGroup#${input}`,
      ),
      offerNetworkStartDate: new InputInjection([0, 'startDate']),
      offerNetworkEndDate: new InputInjection([0, 'endDate']),
      offerUrl: new InputInjection([1, 'url']),
      reportingWindowDays: new InputInjection(
        [0, 'reportingWindowDays'],
        parseInt,
      ),
      validationWindowDays: 90,
      offers: [
        {
          offerNetworkOfferId: new InputInjection(
            [0, 'brandId'],
            (input: any) => `button#offer#${input}#DYNAMIC`,
          ),
          name: new InputInjection(
            [0, 'brandName'],
            (input: any) => `Button - ${input} - Base Dynamic Offer`,
          ),
          description: new InputInjection([0, 'description']),
          commissionType: 'DYNAMIC',
          commissionValue: null,
          commissionValueScaleFactor: null,
          commissionUnits: null,
          commissionCurrency: null,
          commissionSplit: {
            campaignUuid: 'b346b89a-328f-4c86-a374-8e553bcddcd0',
            budgetUuid: '387d7dd2-c669-42bb-8fef-71b54322b39e',
            units: 'PERCENTAGE',
            value: 0,
            scaleFactor: 1,
            calculationMethod: 'FLOOR_PERCENT_CASHBACK_REMAINDER_MARKETING',
          },
          startDate: new InputInjection([0, 'startDate']),
          endDate: new InputInjection([0, 'endDate']),
        },
      ],
    },
  },
  {
    name: 'Query redshift',
    autorun: true,
    process: {
      type: WorkflowStepTypes.REDSHIFT_QUERY,
    },
    inputData: {
      query: new InputInjection(
        [0, 'brandId'],
        (
          input: any,
        ) => `select c.campaign_uuid,c.budget_uuid,o.name,o.uuid as offerUuid,o.description,og.offer_network_start_date,og.offer_network_end_date
          from affiliateoffersdb.offer_group og
          join affiliateoffersdb.offer o on og.id = o.offer_group_id
          join affiliateoffersdb.commission_split c on o.id = c.offer_id
          where 1 = 1
          and name ilike 'Button%${input}%'`,
      ),
    },
  },
];

export const addButtonMerchant = {
  steps,
  inputValues,
  name: 'Add a new button merchant',
  description:
    'Onboard a new button merchant once their assets have been added to cloudnary. Use the get button merchant tool to pre-fill this forms org id and url.',
  tags: [Tags.specialMerchants],
};
