import {
  generateMatcher,
  Specificity,
} from '@dosh/commons-node-matcher-generator';
import { WorkflowStepTypes } from '@/components/Workflow';
import InputInjection from '@/utils/workflow/InputInjection';
import {
  InputFieldConfigs,
  InputFieldTypes,
} from '@/components/WorkflowForm/WorkflowForm';
import { ADD_BRAND } from '../graphql/addBrand.gql';
import { MatcherObject, Tags } from '@/helpers/types';
import { UPSERT_PLACE_MATCHERS } from '../graphql/upsertPlaceMatchers.gql';
import { SET_BRAND_INFO } from '../graphql/setBrandInfo.gql';
import { regexSpecificityOptions } from '@/helpers/commonInputSelectOptions';

const inputValues = [
  {
    name: 'name',
    formLabel: 'Name of merchant',
  },
  {
    name: 'specificity',
    formLabel: 'Level of Specificity',
    options: regexSpecificityOptions,
    type: InputFieldTypes.SELECT,
  },
] as InputFieldConfigs[];

const steps = [
  {
    name: 'Generate matcher and brand id',
    autorun: true,
    process: {
      type: WorkflowStepTypes.PREPROCESS_DATA,
    },
    inputData: {
      brandId: new InputInjection([0], ({ name }: { name: string }) => {
        return `CDLX_${name ? name.replaceAll(/\W/gi, '').toUpperCase() : ''}`;
      }),
      createdMatcher: new InputInjection(
        [0],
        ({ name, specificity }: { name: string; specificity: string }) => {
          if (!name || !specificity) {
            return;
          }
          const matcher = generateMatcher({
            name,
            specificity:
              specificity === 'GENERIC'
                ? Specificity.GENERIC
                : Specificity.SPECIFIC,
          });
          return matcher;
        },
      ),
    },
  },
  {
    name: 'Add Brand Id',
    autorun: true,
    inputData: {
      brandId: new InputInjection([1], ({ brandId }: { brandId: string }) => {
        if (!brandId) {
          return;
        }
        return brandId;
      }),
    },
    process: {
      type: WorkflowStepTypes.GRAPHQL_MUTATION,
      submit: ADD_BRAND,
    },
  },
  {
    name: 'Set Brand Info',
    autorun: true,
    inputData: {
      brandId: new InputInjection([1], ({ brandId }: { brandId: string }) => {
        if (!brandId) {
          return;
        }
        return brandId;
      }),
      info: {
        name: new InputInjection([0, 'name']),
      },
    },
    process: {
      type: WorkflowStepTypes.GRAPHQL_MUTATION,
      submit: SET_BRAND_INFO,
    },
  },
  {
    name: 'Upsert New Place Matcher',
    autorun: true,
    process: {
      type: WorkflowStepTypes.GRAPHQL_MUTATION,
      submit: UPSERT_PLACE_MATCHERS,
    },
    inputData: {
      metaData: {
        autoSkip: new InputInjection(
          [1],
          ({ createdMatcher }: { createdMatcher: MatcherObject }) => {
            if (!createdMatcher) {
              return;
            }
            return createdMatcher.pattern.length < 4;
          },
        ),
      },
      id: new InputInjection([1], ({ brandId }: { brandId: string }) => {
        if (!brandId) {
          return;
        }
        return brandId;
      }),
      matchers: new InputInjection(
        [1],
        ({ createdMatcher }: { createdMatcher: MatcherObject }) => {
          if (!createdMatcher) {
            return;
          }
          return [createdMatcher];
        },
      ),
    },
  },
];

export const setupCardlyticsBrand = {
  steps,
  inputValues,
  name: 'Setup Cardlytics Brand',
  description: 'Setup Cardlytics brand',
  tags: [Tags.specialMerchants],
};
