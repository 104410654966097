import { WorkflowStepTypes } from '@/components/Workflow';
import InputInjection from '@/utils/workflow/InputInjection';
import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { Tags } from '@/helpers/types';

const inputValues = [
  {
    name: 'brandId',
    formLabel: 'Cardlytics Brand Id',
  },
] as InputFieldConfigs[];

const steps = [
  {
    name: 'Check if Cardlytics Locations Already Exist',
    autorun: true,
    process: {
      type: WorkflowStepTypes.REDSHIFT_QUERY,
    },
    inputData: {
      query: new InputInjection(
        [0],
        ({
          brandId,
        }: {
          brandId: string;
        }) => `SELECT COUNT(DISTINCT v.id) as venue_count
        FROM cnvenuedb.venues v
        JOIN cnvenuedb.venue_sources vs ON vs.id = v.venue_source_id
        JOIN cnvenuedb.names n ON n.id = v.name_id
        JOIN cnvenuedb.addresses a ON a.id = v.address_id
        WHERE vs.name = 'CDLX' AND v.external_group_id = '${brandId}';`,
      ),
    },
  },
  {
    name: 'Results',
    autorun: true,
    process: {
      type: WorkflowStepTypes.PREPROCESS_DATA,
    },
    inputData: {
      shouldLoadVenues: new InputInjection(
        [1],
        ({ records }: { records: { records: { venue_count: number }[] } }) => {
          if (!records || !Array.isArray(records)) {
            return;
          }
          return records[0].venue_count > 0
            ? `${records[0].venue_count} venues found, only need to add locations if this number seems low`
            : 'No venues found, should add locations with search-service data';
        },
      ),
    },
  },
];

export const cardlyticsMerchanttImplementationPrecheck = {
  steps,
  inputValues,
  name: 'CDLX Merchant Implementation PreCheck',
  description: 'Check if Cardlytics merchant needs to have locations loaded',
  tags: [Tags.newMerchantOnboarding, Tags.cdlx],
};
