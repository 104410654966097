import { WorkflowStepTypes } from '@/components/Workflow';
import InputInjection from '@/utils/workflow/InputInjection';
import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { CardNetworkIdType, Tags } from '@/helpers/types';
import { CardNetworksArray } from '@/helpers/typeHelpers';
import { redshiftEscape } from '@/utils/strings';

const inputValues = [
  {
    name: 'merchantName',
    formLabel: 'Merchant Name',
  },
] as InputFieldConfigs[];

const steps = [
  {
    name: 'Number of Nearby Locations on Offer',
    autorun: true,
    process: {
      type: WorkflowStepTypes.REDSHIFT_QUERY,
    },
    inputData: {
      query: new InputInjection(
        [0],
        ({ merchantName }: { merchantName: string }) =>
          `SELECT COUNT(DISTINCT l.location_id) as location_count
          FROM merchantdb.offers o
          JOIN merchantdb.campaigns c ON o.offer_id = c.offer_id
          JOIN merchantdb.coupons c2 ON o.coupon_id = c2.coupon_id
          JOIN merchantdb.merchants m on o.merchant_id = m.merchant_id
          JOIN merchantdb.coupons_locations cl on c2.coupon_id = cl.coupon_id
          JOIN merchantdb.locations l on cl.location_id = l.location_id
          WHERE m.name ilike '%${redshiftEscape(merchantName)}%'   
          AND c.end_date > current_date
          AND l.type = 'nearby'
          ; `,
      ),
    },
  },
  {
    name: 'Match Rate',
    autorun: true,
    process: {
      type: WorkflowStepTypes.REDSHIFT_QUERY,
    },
    inputData: {
      query: new InputInjection(
        [0],
        ({ merchantName }: { merchantName: string }) =>
          `with venue_data as (
            SELECT l.location_id,
                COUNT(DISTINCT l.location_id)                                                  as total_locations,
                COUNT(DISTINCT CASE when cnv.id is not null then l.location_id else null end)  as mastercard_matched_count,
                COUNT(DISTINCT CASE when cnvv.id is not null then l.location_id else null end) as visa_matched_count,
                COUNT(DISTINCT CASE when cnva.id is not null then l.location_id else null end) as amex_matched_count
            FROM merchantdb.locations l
                     JOIN merchantdb.source_locations sl on l.location_id = sl.location_id
                     JOIN cnvenuedb.venues onv on onv.uuid = sl.source_location_id
                     LEFT JOIN cnvenuedb.offernetwork_to_cardnetwork_venue_mappings otcvm on otcvm.on_venue_id = onv.id
                     LEFT JOIN cnvenuedb.venues cnv on cnv.id = otcvm.cn_venue_id AND cnv.venue_source_id = 3 AND cnv.enabled = 1
                     LEFT JOIN cnvenuedb.offernetwork_to_cardnetwork_venue_mappings otcvmv on otcvmv.on_venue_id = onv.id
                     LEFT JOIN cnvenuedb.venues cnvv on cnvv.id = otcvmv.cn_venue_id AND cnvv.venue_source_id = 4 AND cnvv.enabled = 1
                     LEFT JOIN cnvenuedb.offernetwork_to_cardnetwork_venue_mappings otcvma on otcvma.on_venue_id = onv.id
                     LEFT JOIN cnvenuedb.venues cnva on cnva.id = otcvma.cn_venue_id AND cnva.venue_source_id = 2 AND cnva.enabled = 1
            WHERE l.name ilike '%${redshiftEscape(merchantName)}%'
            AND l.status = 'active'
            AND l.type = 'nearby'
            GROUP BY l.location_id
        ), data_totals as ( SELECT SUM(vd.total_locations) as total_locations,
                 SUM(vd.mastercard_matched_count) as mastercard_matches,
                 SUM(vd.visa_matched_count) as visa_matches,
                 SUM(vd.amex_matched_count) as amex_matches
        FROM venue_data vd), data_missing as ( SELECT dt.total_locations, dt.mastercard_matches, dt.total_locations-dt.mastercard_matches as mastercard_missing,
                dt.visa_matches, dt.total_locations-dt.visa_matches as visa_missing,
                dt.amex_matches, dt.total_locations-dt.amex_matches as amex_missing
        FROM data_totals dt)
        SELECT dm.mastercard_matches*100/dm.total_locations as mastercard_match_rate,
               dm.visa_matches*100/dm.total_locations as visa_match_rate,
               dm.amex_matches*100/dm.total_locations as amex_match_rate
        FROM data_missing dm;`,
      ),
      retries: 10,
    },
  },
  {
    name: 'Active VMIDs',
    autorun: true,
    process: {
      type: WorkflowStepTypes.REDSHIFT_QUERY,
    },
    inputData: {
      query: new InputInjection(
        [0],
        ({ merchantName }: { merchantName: string }) =>
          `with onvenues as (
            SELECT COUNT(DISTINCT onv.id) as onvenue_count, onv.id as onv_id
            FROM cnvenuedb.venues onv
            JOIN merchantdb.source_locations sl on sl.source_location_id = onv.uuid
            JOIN merchantdb.locations l on l.location_id = sl.location_id
            JOIN merchantdb.merchants m on m.merchant_id = l.merchant_id
            WHERE 1=1
            AND m.name ilike '%${redshiftEscape(merchantName)}%'
            AND l.type = 'nearby'
            AND l.status = 'active'
            GROUP BY 2
        ), total_onvenues as (
            SELECT SUM(onvenue_count)
            FROM onvenues
        ), total_cnvenues_per_vmid as(
            SELECT COUNT(DISTINCT cnv.id) as cnvenues_per_vmid_count, cnis.external_id as vmid
            FROM onvenues ot
            JOIN cnvenuedb.venues onv on ot.onv_id = onv.id
            JOIN cnvenuedb.offernetwork_to_cardnetwork_venue_mappings otcvm on otcvm.on_venue_id = onv.id AND onv.venue_source_id = 1
            JOIN cnvenuedb.venues cnv on cnv.id = otcvm.cn_venue_id AND cnv.venue_source_id = 4 AND cnv.enabled = 1
            JOIN cnvenuedb.venue_to_card_network_id vtcni on vtcni.venue_id = cnv.id
            JOIN cnvenuedb.card_network_ids cnis on cnis.id = vtcni.card_network_id AND cnis.id_type = 'VMID'
            JOIN cnvenuedb.names n2 on cnv.name_id = n2.id
            WHERE 1=1
            GROUP BY 2
        ) SELECT tcpv.vmid, vtfsvc.merchant_group
        FROM total_cnvenues_per_vmid tcpv
        LEFT JOIN cnmgmtdb.view_transaction_flow_source_visa_current vtfsvc on vtfsvc.card_network_external_id = tcpv.vmid AND vtfsvc.card_network_external_id_type = 'VMID'
        WHERE vtfsvc.state = 'ACTIVE'
        AND ((CAST(cnvenues_per_vmid_count as float)/(SELECT * FROM total_onvenues))*100)  >= 1;`,
      ),
      retries: 10,
    },
  },
  {
    name: 'Active VSIDs',
    autorun: true,
    process: {
      type: WorkflowStepTypes.REDSHIFT_QUERY,
    },
    inputData: {
      query: new InputInjection(
        [0],
        ({ merchantName }: { merchantName: string }) =>
          `with vsids as (
            select
            n.name,
            onv.external_group_id,
            cn.external_id as cnid,
            oncv.on_venue_id,
            onv.enabled as onv_enabled,
            cnv.enabled as cnv_enabled,
            cn.card_network,
            cn.id_type
        from cnvenuedb.card_network_ids cn
        join cnvenuedb.venue_to_card_network_id vtcni on cn.id = vtcni.card_network_id
        join cnvenuedb.venues cnv on cnv.id = vtcni.venue_id
        join cnvenuedb.offernetwork_to_cardnetwork_venue_mappings oncv on oncv.cn_venue_id = vtcni.venue_id
        join cnvenuedb.venues onv on onv.id = oncv.on_venue_id
        join cnvenuedb.names n on n.id= onv.name_id
        where 1=1
        and n.name ilike '%${redshiftEscape(merchantName)}%'
        and cn.id_type = 'VSID'
        and cnv.enabled = 1
        and onv.enabled = 1
        ), vsid_states as (
        SELECT COUNT(CASE state WHEN 'ACTIVE' THEN 1 ELSE null END) as active_vsids, COUNT(CASE state WHEN 'INACTIVE' THEN 1 ELSE null END) as inactive_vsids
        FROM cnmgmtdb.view_transaction_flow_source_visa_current vtfsvc
        WHERE card_network_external_id in (select distinct cnid from vsids)
        AND vtfsvc.merchant_group = 'Dosh Master MG' -- onboarding automation will only activate in this group
        )
        SELECT (CAST(active_vsids as float)/(active_vsids+inactive_vsids))*100 as percent_active
        FROM vsid_states vs;
        ;`,
      ),
      retries: 10,
    },
  },
  {
    name: 'Active SEs',
    autorun: true,
    process: {
      type: WorkflowStepTypes.REDSHIFT_QUERY,
    },
    inputData: {
      query: new InputInjection(
        [0],
        ({ merchantName }: { merchantName: string }) =>
          `with ses as (
            select
            n.name,
            onv.external_group_id,
            cn.external_id as cnid,
            oncv.on_venue_id,
            onv.enabled as onv_enabled,
            cnv.enabled as cnv_enabled,
            cn.card_network,
            cn.id_type
        from cnvenuedb.card_network_ids cn
        join cnvenuedb.venue_to_card_network_id vtcni on cn.id = vtcni.card_network_id
        join cnvenuedb.venues cnv on cnv.id = vtcni.venue_id
        join cnvenuedb.offernetwork_to_cardnetwork_venue_mappings oncv on oncv.cn_venue_id = vtcni.venue_id -- card network
        join cnvenuedb.venues onv on onv.id = oncv.on_venue_id -- offer network
        join cnvenuedb.names n on n.id= onv.name_id
        where 1=1
        and n.name ilike '%${redshiftEscape(merchantName)}%'
        and cn.id_type = 'SE'
        and cnv.enabled = 1
        and onv.enabled = 1
        ), se_states as (
        SELECT COUNT(CASE state WHEN 'ACTIVE' THEN 1 ELSE null END)   as active_ses,
               COUNT(CASE state WHEN 'INACTIVE' THEN 1 ELSE null END) as inactive_ses
        FROM cnmgmtdb.view_transaction_flow_source_amex_current
        WHERE card_network_external_id in (select distinct cnid from ses)
        ) SELECT (CAST(active_ses as float)/(active_ses+inactive_ses))*100 as percent_active
        FROM se_states ss;
        ;`,
      ),
      retries: 10,
    },
  },
  {
    name: 'Transaction Check',
    autorun: true,
    process: {
      type: WorkflowStepTypes.REDSHIFT_QUERY,
    },
    inputData: {
      query: new InputInjection(
        [0],
        ({ merchantName }: { merchantName: string }) =>
          `select
          cted.venue_name,
          cte.card_network,
          cte.marketplace,
          l.type,
          count(DISTINCT cte.transaction_id)
   from empyrdb.card_transaction_events cte
   JOIN empyrdb.card_transaction_events_debug cted on cte.id = cted.card_transaction_event_id
   join empyrdb.card_transaction_events_card_network_ids ctecni on cte.id = ctecni.card_transaction_event_id
   JOIN cnvenuedb.card_network_ids cnis on ctecni.card_network_external_id = cnis.external_id
   JOIN cnvenuedb.venue_to_card_network_id vtcni on vtcni.card_network_id = cnis.id
   JOIN cnvenuedb.venues cnv on vtcni.venue_id = cnv.id
   JOIN cnvenuedb.offernetwork_to_cardnetwork_venue_mappings otcvm on otcvm.cn_venue_id = cnv.id
   JOIN cnvenuedb.venues onv on onv.id = otcvm.on_venue_id
   JOIN merchantdb.source_locations sl on sl.source_location_id = onv.uuid
   JOIN merchantdb.locations l on l.location_id = sl.location_id
   where 1=1
   and cte.transaction_timestamp >= current_date-7
   AND cted.venue_name ilike '%${redshiftEscape(merchantName)}%'
   AND cte.marketplace = 'DOSH'
   AND cnis.card_network in ('VISA','MASTERCARD','AMEX')
   AND onv.enabled = 1
   AND l.type = 'nearby'
   group by 4,3,2,1
   ;`,
      ),
      retries: 10,
    },
  },
  {
    name: 'Query to Verify Unknown Location on Offer, Status, and Has Aggregate CNId mappings',
    autorun: true,
    process: {
      type: WorkflowStepTypes.REDSHIFT_QUERY,
    },
    inputData: {
      query: new InputInjection(
        [0],
        ({ merchantName }: { merchantName: string }) =>
          `SELECT DISTINCT l.status, cnis.card_network, cnis.id_type, cnis.external_id
          FROM merchantdb.offers o
          JOIN merchantdb.campaigns c on o.offer_id = c.offer_id
          JOIN merchantdb.coupons c2 on o.coupon_id = c2.coupon_id
          JOIN merchantdb.merchants m on o.merchant_id = m.merchant_id
          JOIN merchantdb.coupons_locations cl on c2.coupon_id = cl.coupon_id
          JOIN merchantdb.locations l on cl.location_id = l.location_id
          JOIN merchantdb.source_locations sl on sl.location_id = l.location_id
          JOIN cnvenuedb.venues onv on onv.uuid = sl.source_location_id
          JOIN cnvenuedb.offernetwork_to_cardnetwork_venue_mappings otcvm on onv.id = otcvm.on_venue_id
          JOIN cnvenuedb.venues cnv on cnv.id = otcvm.cn_venue_id
          JOIN cnvenuedb.venue_to_card_network_id vtcni on vtcni.venue_id = cnv.id
          JOIN cnvenuedb.card_network_ids cnis on cnis.id = vtcni.card_network_id
          WHERE m.name ilike '%${redshiftEscape(merchantName)}%'
          AND l.street_address = '__UNKNOWN__'
          AND cnis.id_type IN ('VMID', 'VSID', 'BRAND')
          AND c.end_date > current_date
          ;`,
      ),
      retries: 10,
    },
  },
  {
    name: 'Membership Offer Check',
    autorun: true,
    process: {
      type: WorkflowStepTypes.REDSHIFT_QUERY,
    },
    inputData: {
      query: new InputInjection(
        [0],
        ({ merchantName }: { merchantName: string }) =>
          `SELECT DISTINCT c2.type, c2.exact_spend_to_activate
          FROM merchantdb.offers o
          JOIN merchantdb.campaigns c ON o.offer_id = c.offer_id
          JOIN merchantdb.coupons c2 ON o.coupon_id = c2.coupon_id
          JOIN merchantdb.merchants m on o.merchant_id = m.merchant_id
          JOIN merchantdb.coupons_locations cl on c2.coupon_id = cl.coupon_id
          JOIN merchantdb.locations l on cl.location_id = l.location_id
          WHERE m.name ilike '%${redshiftEscape(merchantName)}%'
          AND c.end_date > current_date
          AND l.type = 'nearby'
          ;`,
      ),
      retries: 10,
    },
  },
  {
    name: 'Results',
    autorun: false,
    process: {
      type: WorkflowStepTypes.PREPROCESS_DATA,
    },
    inputData: {
      numberOfLocationsOnOffer: new InputInjection(
        [1],
        ({ records }: { records: { location_count: string }[] }) => {
          if (!records || !Array.isArray(records)) {
            return;
          }
          return records[0].location_count || [];
        },
      ),
      matchRate: new InputInjection(
        [2],
        ({
          records,
        }: {
          records: {
            mastercard_match_rate: number;
            visa_match_rate: number;
            amex_match_rate: number;
          }[];
        }) => {
          if (!records || !Array.isArray(records)) {
            return;
          }
          return records[0] || [];
        },
      ),
      vmidsActive: new InputInjection(
        [3],
        ({
          records,
        }: {
          records: {
            vmid: string;
            merchant_group: string;
          }[];
        }) => {
          if (!records || !Array.isArray(records)) {
            return;
          }
          return records.map((record) => record.vmid);
        },
      ),
      vmidsActiveGroups: new InputInjection(
        [3],
        ({
          records,
        }: {
          records: {
            vmid: string;
            merchant_group: string;
          }[];
        }) => {
          if (!records || !Array.isArray(records)) {
            return;
          }
          return records.map((record) => record.merchant_group);
        },
      ),
      expectedActiveVsidRatio: new InputInjection(
        [4],
        ({
          records,
        }: {
          records: {
            percent_active: number;
          }[];
        }) => {
          if (!records || !Array.isArray(records)) {
            return;
          }
          return records[0].percent_active > 80 || [];
        },
      ),
      expectedActiveSeRatio: new InputInjection(
        [5],
        ({
          records,
        }: {
          records: {
            percent_active: number;
          }[];
        }) => {
          if (!records || !Array.isArray(records)) {
            return;
          }
          return records[0].percent_active > 80 || [];
        },
      ),
      transactionsReceivedForAllNetworks: new InputInjection(
        [6],
        ({
          records,
        }: {
          records: {
            card_network: string;
          }[];
        }) => {
          if (!records || !Array.isArray(records)) {
            return;
          }
          return records.filter((record) =>
            CardNetworksArray.includes(record.card_network),
          );
        },
      ),
      unknownLocationOnOfferAndActive: new InputInjection(
        [7],
        ({
          records,
        }: {
          records: {
            status: string;
          }[];
        }) => {
          if (!records || !Array.isArray(records)) {
            return;
          }
          return records.length && records[0].status === 'active';
        },
      ),
      aggregateBrandCNIdsMapped: new InputInjection(
        [7],
        ({
          records,
        }: {
          records: {
            id_type: string;
          }[];
        }) => {
          if (!records || !Array.isArray(records)) {
            return;
          }
          return (
            records.length &&
            records.some((record) => record.id_type === CardNetworkIdType.BRAND)
          );
        },
      ),
      aggregateVMIDMapped: new InputInjection(
        [7],
        ({
          records,
        }: {
          records: {
            id_type: string;
          }[];
        }) => {
          if (!records || !Array.isArray(records)) {
            return;
          }
          const vsidsAndVmidsMapped = records.filter(
            (record) =>
              record.id_type === CardNetworkIdType.VMID ||
              record.id_type === CardNetworkIdType.VSID,
          );
          // an odd number of visa cnid mappings (vsids and vmids only) indicate an aggregate VMID mapping as all other Visa cnvenues will have an even number (VSID and VMID)
          return (
            vsidsAndVmidsMapped.length && vsidsAndVmidsMapped.length % 2 !== 0
          );
        },
      ),
      offerIsValidMembershipOffer: new InputInjection(
        [8],
        ({
          records,
        }: {
          records: {
            type: string;
            exact_spend_to_activate: string;
          }[];
        }) => {
          if (!records || !Array.isArray(records)) {
            return;
          }
          return (
            typeof records[0].exact_spend_to_activate === 'string' &&
            records[0].type === 'loyalty'
          );
        },
      ),
    },
  },
];

export const nearbyQASession = {
  steps,
  inputValues,
  name: 'QA Nearby Offer',
  description: 'Nearby Only QA',
  tags: [Tags.qa, Tags.newMerchantOnboarding],
};
