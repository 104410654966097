import { WorkflowStepTypes } from '@/components/Workflow';
import InputInjection from '@/utils/workflow/InputInjection';
import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { Tags } from '@/helpers/types';

const inputValues = [
  {
    name: 'name',
    formLabel: 'Cardlytics Merchant Name',
  },
] as InputFieldConfigs[];

const steps = [
  {
    name: 'Get Cardlytics Brand Id',
    autorun: true,
    process: {
      type: WorkflowStepTypes.PREPROCESS_DATA,
    },
    inputData: {
      brandId: new InputInjection([0], ({ name }: { name: string }) => {
        return `CDLX_${name ? name.replaceAll(/\W/gi, '').toUpperCase() : ''}`;
      }),
    },
  },
];

export const getBrandIdForCardlyticsBrand = {
  steps,
  inputValues,
  name: 'Get Brand Id for Cardlytics Brand',
  description: 'Look up brand id for a Cardlytics brand my name of merchant',
  tags: [Tags.specialMerchants],
};
