import { WorkflowStepTypes } from '@/components/Workflow';
import InputInjection from '@/utils/workflow/InputInjection';
import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import {
  CardNetwork,
  CardNetworkIdType,
  LocationType,
  MatchBasis,
  OfferNetwork,
  Tags,
  Venue,
} from '@/helpers/types';
import { upsertONVenue } from '../lambdaFunctions/upsertONVenue';
import { addManualONVenueToCNIdMapping } from '../lambdaFunctions/addManualONVenueToCNIdMapping';

const inputValues = [
  {
    name: 'cdlxBrandId',
    formLabel: 'CDLX Brand Id (number from CDLX)',
  },
  {
    name: 'cdlxBrandName',
    formLabel: 'CDLX Brand Name (name from CDLX)',
  },
  {
    name: 'vsid',
    formLabel: 'VSID Associated with Online Transactions',
  },
] as InputFieldConfigs[];

const steps = [
  {
    name: 'Create Online Venue',
    autorun: true,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: upsertONVenue.submit,
    },
    inputData: {
      venue: {
        enabled: false,
        name: new InputInjection([0, 'cdlxBrandName']),
        venueSource: OfferNetwork.CDLX,
        externalId: new InputInjection([0, 'cdlxBrandId']),
        externalGroupId: new InputInjection([0, 'cdlxBrandId']),
        locationType: LocationType.ONLINE,
        address: {
          address1: '__ONLINE__',
          city: '__ONLINE__',
          state: '__',
          postal: '_____',
          country: '__',
        },
      },
    },
  },
  {
    name: 'Add VSID Mapping',
    autorun: true,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: addManualONVenueToCNIdMapping.submit,
    },
    inputData: {
      onvenueUuid: new InputInjection(
        [1],
        ({ result }: { result: { venue: Venue } }) => {
          if (!result) {
            return;
          }
          return result.venue.uuid;
        },
      ),
      cardNetwork: CardNetwork.VISA,
      cardNetworkExternalIdType: CardNetworkIdType.VSID,
      cardNetworkExternalId: new InputInjection([0, 'vsid']),
      basis: MatchBasis.PLACE_MATCH,
    },
  },
];

export const createAndMapCdlxOnlineLocation = {
  steps,
  inputValues,
  name: 'Create Online Location for CDLX Merchant',
  description: 'Create online location for CDLX merchant and map to VSID',
  tags: [
    Tags.newMerchantOnboarding,
    Tags.venues,
    Tags.cdlx,
    Tags.specialMerchants,
  ],
};
