import { WorkflowStepTypes } from '@/components/Workflow';
import InputInjection from '@/utils/workflow/InputInjection';
import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { createAgentAndMapToReferralId } from '../lambdaFunctions/createAgentAndMapToReferralId';
import { Tags } from '@/helpers/types';
import { redshiftEscape } from '@/utils/strings';

const inputValues = [
  {
    name: 'merchantName',
    formLabel: 'Merchant Name',
  },
  {
    name: 'externalId',
    formLabel: 'Agent Email',
  },
] as InputFieldConfigs[];

const steps = [
  {
    name: 'Get Merchant Uuid',
    autorun: true,
    process: {
      type: WorkflowStepTypes.REDSHIFT_QUERY,
    },
    inputData: {
      query: new InputInjection(
        [0],
        ({ merchantName }: { merchantName: string }) =>
          `SELECT getuuid(merchant_id) AS merchant_uuid , name
        FROM merchantdb.merchants
        WHERE name ilike '%${redshiftEscape(merchantName)}%'`,
      ),
    },
  },
  {
    name: 'Create Agent and Map Referral',
    autorun: true,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: createAgentAndMapToReferralId.submit,
    },
    inputData: {
      referralId: new InputInjection([1], ({ records }: any) => {
        if (!records || !Array.isArray(records)) {
          return;
        }
        if (records.length > 1) {
          return;
        }
        return records[0].merchant_uuid;
      }),
      externalId: new InputInjection([0, 'externalId']),
      referralType: 'MERCHANT',
      referralNetworkName: 'DERBYSHIRE',
      accountType: 'SALES_REPRESENTATIVE',
    },
  },
];

export const addDerbyshireAgent = {
  steps,
  inputValues,
  name: 'Add Derbyshire agent to merchant',
  description: 'Add agent email',
  tags: [Tags.specialMerchants],
};
