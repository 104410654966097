import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { Tags } from '@/helpers/types';
import lambdaConstructor, {
  HttpMethod,
} from '@/utils/lambdaApi/lambdaConstructor';

const inputValues = [
  {
    name: 'offerGroupUuid',
    formLabel: 'Offer Group Uuid',
  },
  {
    name: 'cashbackRestrictions',
    formLabel: 'Restrictions',
  },
  {
    name: 'offerUrl',
    formLabel: 'Offer Url',
  },
] as InputFieldConfigs[];

const submit = lambdaConstructor({
  serviceName: 'affiliate-offer-svc',
  functionName: 'updateOfferGroupContent',
  method: HttpMethod.POST,
});

export const updateOfferGroupContent = {
  name: 'Update Offer Group Content',
  description: 'Update content associated with Button offers',
  inputValues,
  outputValueName: 'Results',
  tags: [Tags.specialMerchants],
  submit,
};
