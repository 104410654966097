import { WorkflowStepTypes } from '@/components/Workflow';
import InputInjection from '@/utils/workflow/InputInjection';
import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { toggleCNTransactionsForCNId } from '../lambdaFunctions/toggleCNTransactionsForCNId';
import {
  CardNetwork,
  CardNetworkIdType,
  CardNetworkTransactionMode,
  Tags,
  VisaCommunityCode,
  VisaMerchantGroup,
} from '@/helpers/types';
import { redshiftEscape } from '@/utils/strings';
import { getArrayFromCheckboxSelections } from '@/helpers/getArrayFromCheckboxSelections';
import { castStringToVisaExternalId } from '@/helpers/castStringToVisaExternalId';

const inputValues = [
  {
    name: 'merchantName',
    formLabel: 'Merchant Name for CDLX Merchant',
  },
  {
    name: 'advertiserName',
    formLabel: 'Advertiser Name from Ads Manager (Desired Name on Offer)',
  },
] as InputFieldConfigs[];

const steps = [
  {
    name: 'Get CDLX Brand Id',
    autorun: true,
    process: {
      type: WorkflowStepTypes.REDSHIFT_QUERY,
    },
    inputData: {
      query: new InputInjection(
        [0],
        ({ merchantName }: { merchantName: string }) =>
          `SELECT
          DISTINCT v.external_group_id as brand_id, n.name
        FROM cnvenuedb.venues v
        JOIN cnvenuedb.venue_sources vs ON vs.id = v.venue_source_id
        JOIN cnvenuedb.names n ON n.id = v.name_id
        JOIN cnvenuedb.addresses a ON a.id = v.address_id
        WHERE vs.name = 'CDLX' AND n.name ilike '%${redshiftEscape(
          merchantName,
        )}%';`,
      ),
      retries: 10,
    },
  },
  {
    name: 'Select CDLX Merchant to Activate',
    autorun: false,
    process: {
      type: WorkflowStepTypes.CHECKBOX_SELECTOR,
      submit: () => {},
    },
    inputData: {
      options: new InputInjection(
        [1],
        ({
          records,
        }: {
          records: { records: { brand_id: string; name: string }[] };
        }) => {
          if (!records || !Array.isArray(records)) {
            return;
          }
          return records.map((record) => {
            return {
              id: `${record.brand_id}#${record.name}`,
              name: `CDLX Brand Id: ${record.brand_id}; Merchant Name: ${record.name}`,
            };
          });
        },
      ),
    },
  },
  {
    name: 'Results',
    autorun: true,
    process: {
      type: WorkflowStepTypes.PREPROCESS_DATA,
    },
    inputData: {
      brandId: new InputInjection([2], (result: Record<string, any>) => {
        if (!result || typeof result === 'string') {
          return;
        }
        return getArrayFromCheckboxSelections(result)[0].split('#')[0];
      }),
      name: new InputInjection([2], (result: Record<string, any>) => {
        if (!result || typeof result === 'string') {
          return;
        }
        return getArrayFromCheckboxSelections(result)[0].split('#')[1];
      }),
    },
  },
  {
    name: 'Get Potential VMIDs to Activate',
    autorun: true,
    process: {
      type: WorkflowStepTypes.REDSHIFT_QUERY,
    },
    inputData: {
      query: new InputInjection(
        [3],
        ({ brandId }: { brandId: string }) =>
          `with onvenue_total as (
            SELECT COUNT(DISTINCT onv.id) as onvenue_count, onv.external_group_id as external_group_id
            FROM cnvenuedb.venues onv
            JOIN cnvenuedb.names n on onv.name_id = n.id
            WHERE 1=1
            AND onv.external_group_id = '${brandId}'
            AND onv.venue_source_id = 9
            GROUP BY 2
        ), total_cnvenues_per_vmid as(
            SELECT COUNT(DISTINCT cnv.id) as cnvenues_per_vmid_count, cnis.external_id as vmid, ot.onvenue_count as onvenue_count
            FROM onvenue_total ot
            JOIN cnvenuedb.venues onv on ot.external_group_id = onv.external_group_id
            JOIN cnvenuedb.offernetwork_to_cardnetwork_venue_mappings otcvm on otcvm.on_venue_id = onv.id AND onv.venue_source_id = 9
            JOIN cnvenuedb.venues cnv on cnv.id = otcvm.cn_venue_id AND cnv.venue_source_id = 4 AND cnv.enabled = 1
            JOIN cnvenuedb.venue_to_card_network_id vtcni on vtcni.venue_id = cnv.id
            JOIN cnvenuedb.card_network_ids cnis on cnis.id = vtcni.card_network_id AND cnis.id_type = 'VMID'
            JOIN cnvenuedb.names n2 on cnv.name_id = n2.id
            WHERE 1=1
            GROUP BY 2, 3
        ) SELECT DISTINCT (CAST(cnvenues_per_vmid_count as float)/onvenue_count)*100 as percent_of_mapped_vmids, tcpv.vmid
        FROM total_cnvenues_per_vmid tcpv
        LEFT JOIN cnmgmtdb.view_transaction_flow_source_visa_current vtfsvc on vtfsvc.card_network_external_id = tcpv.vmid AND vtfsvc.card_network_external_id_type = 'VMID'
        WHERE (vtfsvc.state != 'ACTIVE' OR vtfsvc.state IS NULL)
        AND percent_of_mapped_vmids >= 1
        ORDER BY percent_of_mapped_vmids DESC;`,
      ),
      retries: 20,
    },
  },
  {
    name: 'Select VMIDs to Activate (Verify in Visa Portal))',
    autorun: false,
    process: {
      type: WorkflowStepTypes.CHECKBOX_SELECTOR,
      submit: () => {},
    },
    inputData: {
      options: new InputInjection(
        [4],
        ({
          records,
        }: {
          records: { percent_of_mapped_vmids: string; vmid: string }[];
        }) => {
          if (!records || !Array.isArray(records)) {
            return;
          }
          return records.map((record) => {
            return {
              id: `${record.vmid}`,
              name: `Percent of Locations with VMID: ${record.percent_of_mapped_vmids}; VMID: ${record.vmid}`,
            };
          });
        },
      ),
    },
  },
  {
    name: 'Process Selections',
    autorun: true,
    process: {
      type: WorkflowStepTypes.PREPROCESS_DATA,
    },
    inputData: {
      selectedVMIDs: new InputInjection([5], (result: Record<string, any>) => {
        if (!result || typeof result === 'string') {
          return;
        }
        return getArrayFromCheckboxSelections(result);
      }),
      visaExternalId: new InputInjection(
        [0],
        ({ advertiserName }: { advertiserName: string }) => {
          if (!advertiserName) {
            return;
          }
          return castStringToVisaExternalId(advertiserName);
        },
      ),
    },
  },
  {
    name: 'Create VMID activation array',
    autorun: true,
    process: {
      type: WorkflowStepTypes.PREPROCESS_DATA,
    },
    inputData: {
      activationArray: new InputInjection(
        [6],
        ({
          selectedVMIDs,
          visaExternalId,
        }: {
          selectedVMIDs: unknown[];
          visaExternalId: string;
        }) => {
          if (!selectedVMIDs) {
            return;
          }
          return !Array.isArray(selectedVMIDs)
            ? []
            : selectedVMIDs.map((selection) => {
                return {
                  mode: CardNetworkTransactionMode.ACTIVATE,
                  cardNetwork: CardNetwork.VISA,
                  cardNetworkExternalIdType: CardNetworkIdType.VMID,
                  cardNetworkExternalId: selection,
                  merchantGroup: VisaMerchantGroup.ACTIVATION_MG,
                  communityCode: VisaCommunityCode.DOSHCL,
                  visaExternalId,
                };
              });
        },
      ),
    },
  },
  {
    name: 'Activate VMID',
    autorun: false,
    process: {
      type: WorkflowStepTypes.LAMBDA,
      submit: toggleCNTransactionsForCNId.submit,
    },
    inputData: {
      multipleInvocations: true,
      invocationArray: new InputInjection([7, 'activationArray']),
    },
  },
];

export const activateCDLXMerchant = {
  steps,
  inputValues,
  name: 'Activate Cardlytics Merchant',
  description: 'Activate Cardlytics merchant',
  tags: [
    Tags.newMerchantOnboarding,
    Tags.transactions,
    Tags.specialMerchants,
    Tags.cdlx,
  ],
};
