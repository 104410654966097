import { WorkflowStepTypes } from '@/components/Workflow';
import InputInjection from '@/utils/workflow/InputInjection';
import { InputFieldConfigs } from '@/components/WorkflowForm/WorkflowForm';
import { Tags } from '@/helpers/types';
import { CardNetworksArray } from '@/helpers/typeHelpers';
import { redshiftEscape } from '@/utils/strings';

const inputValues = [
  {
    name: 'merchantName',
    formLabel: 'CDLX Merchant Name',
  },
] as InputFieldConfigs[];

const steps = [
  {
    name: 'Get CDLX Brand Id',
    autorun: true,
    process: {
      type: WorkflowStepTypes.REDSHIFT_QUERY,
    },
    inputData: {
      query: new InputInjection(
        [0],
        ({ merchantName }: { merchantName: string }) =>
          `SELECT
          DISTINCT v.external_group_id as brand_id
        FROM cnvenuedb.venues v
        JOIN cnvenuedb.venue_sources vs ON vs.id = v.venue_source_id
        JOIN cnvenuedb.names n ON n.id = v.name_id
        JOIN cnvenuedb.addresses a ON a.id = v.address_id
        WHERE vs.name = 'CDLX' AND n.name ilike '%${redshiftEscape(
          merchantName,
        )}%';`,
      ),
      retries: 10,
    },
  },
  {
    name: 'Results',
    autorun: true,
    process: {
      type: WorkflowStepTypes.PREPROCESS_DATA,
    },
    inputData: {
      brandId: new InputInjection(
        [1],
        ({ records }: { records: { brand_id: string }[] }) => {
          if (!records || !Array.isArray(records) || !records[0]) {
            return;
          }
          return records[0].brand_id;
        },
      ),
    },
  },
  {
    name: 'Get Number of ONVenues',
    autorun: true,
    process: {
      type: WorkflowStepTypes.REDSHIFT_QUERY,
    },
    inputData: {
      query: new InputInjection(
        [2],
        ({ brandId }: { brandId: string }) =>
          `SELECT COUNT(DISTINCT onv.id), onv.enabled
          FROM cnvenuedb.venues onv
          WHERE 1=1
          AND onv.external_group_id = '${brandId}'
          AND onv.venue_source_id = 9
          GROUP BY 2;`,
      ),
    },
  },
  {
    name: 'Get Match Rate for CDLX Merchant',
    autorun: true,
    process: {
      type: WorkflowStepTypes.REDSHIFT_QUERY,
    },
    inputData: {
      query: new InputInjection(
        [2],
        ({ brandId }: { brandId: string }) =>
          `with venue_data as (
            SELECT onv.id,
                COUNT(DISTINCT onv.id)                                                  as total_locations,
                COUNT(DISTINCT CASE when cnv.id is not null then onv.id else null end)  as mastercard_matched_count,
                COUNT(DISTINCT CASE when cnvv.id is not null then onv.id else null end) as visa_matched_count,
                COUNT(DISTINCT CASE when cnva.id is not null then onv.id else null end) as amex_matched_count,
                COUNT(DISTINCT CASE when cnvs.id is not null then onv.id else null end) as sentral_matched_count
                 FROM cnvenuedb.venues onv
                 LEFT JOIN cnvenuedb.offernetwork_to_cardnetwork_venue_mappings otcvm on otcvm.on_venue_id = onv.id
                 LEFT JOIN cnvenuedb.venues cnv on cnv.id = otcvm.cn_venue_id AND cnv.venue_source_id = 3 AND cnv.enabled = 1
                 LEFT JOIN cnvenuedb.offernetwork_to_cardnetwork_venue_mappings otcvmv on otcvmv.on_venue_id = onv.id
                 LEFT JOIN cnvenuedb.venues cnvv on cnvv.id = otcvmv.cn_venue_id AND cnvv.venue_source_id = 4 AND cnvv.enabled = 1
                 LEFT JOIN cnvenuedb.offernetwork_to_cardnetwork_venue_mappings otcvma on otcvma.on_venue_id = onv.id
                 LEFT JOIN cnvenuedb.venues cnva on cnva.id = otcvma.cn_venue_id AND cnva.venue_source_id = 2 AND cnva.enabled = 1
                 LEFT JOIN cnvenuedb.offernetwork_to_cardnetwork_venue_mappings otcvms on otcvms.on_venue_id = onv.id
                 LEFT JOIN cnvenuedb.venues cnvs on cnvs.id = otcvms.cn_venue_id AND cnvs.venue_source_id = 8 AND cnvs.enabled = 1
            WHERE onv.external_group_id = '${brandId}'
            AND onv.venue_source_id = 9
            GROUP BY onv.id
        ), data_totals as ( SELECT SUM(vd.total_locations) as total_locations,
                 SUM(vd.mastercard_matched_count) as mastercard_matches,
                 SUM(vd.visa_matched_count) as visa_matches,
                 SUM(vd.amex_matched_count) as amex_matches,
                 SUM(vd.sentral_matched_count) as sentral_matches
        FROM venue_data vd), data_missing as ( SELECT dt.total_locations, dt.mastercard_matches, dt.total_locations-dt.mastercard_matches as mastercard_missing,
                dt.visa_matches, dt.total_locations-dt.visa_matches as visa_missing,
                dt.amex_matches, dt.total_locations-dt.amex_matches as amex_missing,
                dt.sentral_matches, dt.total_locations-dt.sentral_matches as sentral_missing
        FROM data_totals dt)
        SELECT dm.mastercard_matches*100/dm.total_locations as mastercard_match_rate,
               dm.visa_matches*100/dm.total_locations as visa_match_rate,
               dm.amex_matches*100/dm.total_locations as amex_match_rate,
               dm.sentral_matches*100/dm.total_locations as sentral_match_rate
        FROM data_missing dm;`,
      ),
      retries: 10,
    },
  },
  {
    name: 'Get Transactions for CDLX Merchant',
    autorun: true,
    process: {
      type: WorkflowStepTypes.REDSHIFT_QUERY,
    },
    inputData: {
      query: new InputInjection(
        [2],
        ({ brandId }: { brandId: string }) =>
          `SELECT
          cte.card_network,
          COUNT(DISTINCT cte.transaction_id)
   FROM empyrdb.card_transaction_events cte
   JOIN empyrdb.card_transaction_events_debug cted on cte.id = cted.card_transaction_event_id
   JOIN empyrdb.card_transaction_events_card_network_ids ctecni on cte.id = ctecni.card_transaction_event_id
   JOIN cnvenuedb.card_network_ids cnis on ctecni.card_network_external_id = cnis.external_id
   JOIN cnvenuedb.venue_to_card_network_id vtcni on vtcni.card_network_id = cnis.id
   JOIN cnvenuedb.venues cnv on vtcni.venue_id = cnv.id
   JOIN cnvenuedb.offernetwork_to_cardnetwork_venue_mappings otcvm on otcvm.cn_venue_id = cnv.id
   JOIN cnvenuedb.venues onv on onv.id = otcvm.on_venue_id
   WHERE 1=1
   AND cte.transaction_timestamp >= current_date-3
   AND onv.external_group_id = '${brandId}'
   AND onv.venue_source_id = 9
   AND ctecni.card_network_external_id_type not in ('BRAND')
   AND cte.marketplace = 'DOSH'
   AND cnis.card_network in ('VISA','MASTERCARD','AMEX')
   AND onv.enabled = 1
   group by 1
   ;`,
      ),
      retries: 10,
    },
  },
  {
    name: 'Final Results',
    autorun: false,
    process: {
      type: WorkflowStepTypes.PREPROCESS_DATA,
    },
    inputData: {
      numberOfEnabledVenues: new InputInjection(
        [3],
        ({ records }: { records: { count: number; enabled: string }[] }) => {
          if (!records || !Array.isArray(records)) {
            return;
          }
          const enabledVenues = records.find(
            (record) => record.enabled === '1',
          );
          return enabledVenues ? enabledVenues.count : 0;
        },
      ),
      numberOfDisabledVenues: new InputInjection(
        [3],
        ({ records }: { records: { count: number; enabled: string }[] }) => {
          if (!records || !Array.isArray(records)) {
            return;
          }
          const disabledVenues = records.find(
            (record) => record.enabled === '0',
          );
          return disabledVenues ? disabledVenues.count : 0;
        },
      ),
      matchRate: new InputInjection(
        [4],
        ({
          records,
        }: {
          records: {
            mastercard_match_rate: number;
            visa_match_rate: number;
            amex_match_rate: number;
          }[];
        }) => {
          if (!records || !Array.isArray(records)) {
            return;
          }
          return records[0] || [];
        },
      ),
      transactionsReceivedForAllNetworks: new InputInjection(
        [5],
        ({ records }: { records: { card_network: string }[] }) => {
          if (!records || !Array.isArray(records)) {
            return;
          }
          return records.filter((record) =>
            CardNetworksArray.includes(record.card_network),
          );
        },
      ),
    },
  },
];

export const cardlyticsMerchantQASession = {
  steps,
  inputValues,
  name: 'QA Cardlytics Merchant',
  description: 'QA Cardlytics merchant',
  tags: [Tags.qa, Tags.newMerchantOnboarding, Tags.specialMerchants],
};
